.item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
  }
  

  .pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    list-style-type: none;
    gap: 8px;
  }
  
  .pagination__link {
    padding: 8px 12px;
    margin: 0 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-decoration: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .pagination__link:hover {
    background-color: #007bff;
    color: white;
  }
  
  .pagination__link--active {
    background-color: #007bff;
    color: white;
  }
  
  .pagination__link--disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  