.item-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  width: calc(20% - 16px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.item-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.item-info {
  margin-top: 12px;
  font-size: 14px;
}

.item-title {
  font-size: 14px; /* 缩小字体大小 */
  font-weight: bold;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}

.mall-info {
  margin-top: 8px;
  font-size: 12px;
  font-style: italic;
}

.item-details {
  margin-top: 12px;
  font-size: 12px;
  color: #555;
}

button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
